import React, { useEffect, useState } from "react";
import styles from "./NewHomePage.module.css";
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: ''
}

const NewHomePage = () => {
  const [{ email }, setState] = useState(initialState)

  useEffect(() => {
    const fadeElements = document.querySelectorAll(`.${styles.fadeIn}`);
    fadeElements.forEach((el) => {
      el.style.opacity = 1;
    });
  }, []);

  const clearState = () => setState({ ...initialState })

  const handleChange = e => {
    const { name, value } = e.target
    setState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_hwvt664',
        'template_vqzlouu',
        e.target,
        '5NNdZCbaOzC1oEX8r'
      )
      .then(
        result => {
          clearState()
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <>
      <head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DN8WG4BTMY"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-DN8WG4BTMY');
          `}
        </script>
      </head>

      <div className={styles.App}>
        <nav className={styles.navbar}>
          <h1 className={styles.brand}>Inika</h1>
        </nav>
        <div className={styles.container}>
          <div className={styles.fadeContainer}>
            <h1 className={`${styles.fadeIn}`}>Join the Waitlist</h1>
            <p className={`${styles.fadeIn}`}>
              Run your online business in one place with Inika.
            </p>
            <p className={`${styles.fadeIn}`} style={{ marginBottom: "40px" }}>
              Allow our engine to save you time and money every month. Focus on decision-making and product development.
            </p>
            <form className={`${styles.emailForm} ${styles.fadeIn}`} onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleChange}
                className={styles.emailInput}
                required
              />
              <button
                type="submit"
                className={styles.contactButton}
              >
                Join Waitlist
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHomePage;
